/**
 * Represents the different types of account pages.
 */
export enum AccountPageType {
  SETTINGS = 'SETTINGS',
  BILLS = 'BILLS',
  RESERVATIONS = 'RESERVATIONS',
  FRIENDS = 'FRIENDS',
  ADDRESSES = 'ADDRESSES',
  POINTS = 'POINTS',
}
